import dayjs from "dayjs";

import { MAX_EXPIRE_DATE_IN_YEARS } from "../config";

export function useExpireCalendar() {
  const minDate = dayjs().subtract(1, "day").toDate();
  const rangeYears = `${dayjs().year()}:${dayjs()
    .add(MAX_EXPIRE_DATE_IN_YEARS, "year")
    .year()}`;

  return {
    minDate,
    rangeYears,
  };
}
